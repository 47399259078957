/* Scss Document */

$roboto:'Roboto', sans-serif;
$special_font:'Beautiful Every Time';
$light_blue:#6dcff6;
$medium_blue:#4785c5;
$dark_blue:#23455f;
$black:#000000;
$light_purple:#b982a4;
$purple:#b27ea1;
$light_orange:#f1ca9c;
$orange:#e6a265;
$white:#ffffff;
$light_yellow:#fffbc6;
$yellow:#feff84;
$dark_green:#466155;
$light_green:#c4df9b;
$medium_green:#7ba01b;

@mixin fill{
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
}

@mixin img_large{
	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
	font-weight: 400;	
}

body{
	font-family: $roboto, Sans-Serif;
}

ul{
	list-style: none;
	margin: 0;
	padding: 0;
}

a{
	text-decoration: none;
	&:hover{
		text-decoration: none;
	}
}

 @media (min-width: 1500px) {
    .container {
      width: 1385px; } }

div.page{
	overflow: hidden;
}

a.more_button{
	display: inline-block;
	padding: 16px 48px;
	text-transform: uppercase;
	color: $white;
	background-color: $orange;
	border-radius: 10px;
	font-size: 18px;
}

a.user_friendly_click{
	@include fill;
}

div.vertical_height{
	@include fill;
	> div{
		display: table;
		width: 100%;
		height: 100%;
		> div{
			display: table-cell;
			vertical-align: middle;
			height: 100%;
		}
	}
}

div.default_font{
	font-size: 18px;
	line-height: 30px;
	*{
		margin: 0;
	}
	h1{
		
	}
	h2{
		font-size: 36px;
	}
	h3{
		font-size: 30px;
		line-height: 35px;
	}
	h4{
		font-size: 24px;
	}
	h5{
		
	}
	h6{
		
	}
	p{
		
	}
	ul{
		li{
			background-position: left top;
			background-repeat: no-repeat;
			background-image: url('/img/list_mark.png');
			padding-left: 33px;
			margin-bottom: 34px;
			ul{
				padding-top: 30px;
				padding-left: 0;
				li{
					color: $dark_blue;
					margin-bottom: 0;
					padding-left: 0;
					background-image: none;
				}
			}
		}
	}
}

div.default_font2{
	font-size: 14px;
	line-height: 24px;
	*{
		margin: 0;
	}
	h1{
		
	}
	h2{
		font-size: 36px;
	}
	h3{
		font-size: 30px;
		line-height: 35px;
	}
	h4{
		font-size: 24px;
	}
	h5{
		
	}
	h6{
		
	}
	p{
		padding-bottom: 35px;
		&:last-child{
			padding-bottom: 0;
		}
	}
}

div.special_header{
	div.default_font{
		h3{
			font-family: $special_font;
			padding-bottom: 30px;
			line-height: 30px;
			text-transform: uppercase;	
		}
		h2{
			font-size: 48px;
			color: $white;
			text-transform: uppercase;
			font-family: $special_font;
		}
	}
}

header.header{
	padding: 10px 0 13px 0;
	div.logo_box{
		
	}
	div.menu_box{
		nav.menu{
			padding-top: 55px;
			ul{
				li{
					display: inline-block;
					vertical-align: middle;
					margin-right: 5%;
					&:last-child{
						margin-right: 0;
					}
					a{
						padding-bottom: 8px;
						font-size: 14px;
						font-weight: 900;
						color: $black;
						text-transform: uppercase;
						position: relative;
						&:after{
							content:"";
							display:block;
							width:0%;
							position: absolute;
							left:0;
							border-bottom:3px solid #69a4d8;
							transition:0.3s ease;
							-o-transition:0.3s ease;
							-webkit-transition:0.3s ease;
							-moz-transition:0.3s ease;
							-ms-transition:0.3s ease;
							top:100%;
						}
						&:hover{
							&:after{
							left:0%;
							width:100%;
							}
						}
					}
				}
			}
		}
	}
}

section.top{
	position: relative;
	min-height: 0;
	padding-bottom: 34%;
	width: 100%;
	@include img_large;
	background-image: url('/img/blue_bg_top.jpg');
	background-attachment: fixed;
	div.icon_box{
		position: absolute;
		background-position: center top;
		background-repeat: no-repeat;
		background-size: contain;
	}
	div.ib1{
		top: 20%;
		left: 0;
		width: 18%;
		height: 0;
		padding-bottom: 29.4%;
		background-image: url('/img/top_icon1.png');
	}
	div.ib2{
		left: 23%;
		top: 12%;
		width: 18%;
		height: 0;
		padding-bottom: 15%;
		background-image: url('/img/top_icon2.png');
	}
	div.ib3{
		width: 39%;
		height: 0;
		padding-bottom: 37%;
		background-image: url('/img/top_icon3.png');
		left: 44%;
		top: 15%;
		z-index: 2;
	}
	div.ib4{
		width: 21%;
		padding-bottom: 15%;
		height: 0;
		background-image: url('/img/top_icon4.png');
		right: 4.7%;
		top: 7%;
	}
	div.ib5{
		width: 17%;
		height: 0;
		padding-bottom: 11%;
		right: 0;
		top: 72%;
		background-image: url('/img/top_icon5.png');
	}
	div.top_desc{
		width: 22%;
		position: absolute;
		left: 10%;
		color: $white;
		text-align: center;
		top: 67%;
		left: 21%;
		div.default_font{
			text-transform: uppercase;
			h3{
				font-size: 1.55vw;
				line-height: 1.83vw;
			}
		}
	}
	div.top_inside{
		@include fill;
		background-image: url('/img/kids_top.png');
		@include img_large;
	}
}

section.home_about{
	padding-top: 6%;
	div.home_about_image{
		position: relative;
		top: 9px;
		width: 100%;
		img{
			width: 100%;
			height: auto;
		}
	}
	div.desc{
		padding-bottom: 10%;
		text-align: center;
		div.special_header{
			div.default_font{
				h3{
					color: $medium_blue;
				}
			}
		}
		padding-top: 80px;
		> div.default_font{
			padding-bottom: 5%;
		}
	}
	div.flex_box{
		display: flex;
		> div.col-md-6{
			&:first-child{
				align-self: flex-end;
			}
		}
	}
}

section.home_offer{
	padding: 69px 0 42px 0;
	background-color: $purple;
	div.home_offer_top{
		padding-bottom: 70px;
	}
	div.home_offer_icon{
		padding-top: 18px;
	}
	div.special_header{
		div.default_font{
			h3{
				color: $light_yellow;
				padding-bottom: 10px;
			}
		}
	}
	div.offser_box{
		margin-bottom: 42px;
	}
}

section.slider{
	width: 100%;
	min-height: 650px;
	position: relative;
	@include img_large;
	background-image: url('/img/slider_bg.jpg');
	div.vertical_height{
		z-index: 2;
		> div{
			> div{
				position: relative;
			}
		}
		div.slider_arr{
			width: 10%;
			height:100%;
			top:0;
			position:absolute;
			display:block;
			&.arrow{
				background-position: center top 59%;
				background-repeat: no-repeat;
				cursor: pointer;
				z-index:10;
			}
			&.arr_left{
				background-image: url('/img/slider_arr_left.png')
			}
			&.arr_right{
				right:0;
				background-image: url('/img/slider_arr_right.png')
			}
		}
		div.slider_content{
			display:block;
			width: 100%;
			overflow: hidden;
			padding-top:7%;
			ul{
				text-align: center;
				white-space:nowrap;
				position:relative;
				li{
					display:block;
					display:inline-block;
					width:100%;
					white-space: normal;
					div.special_header{
						div.default_font{
							h3{
								padding-bottom: 138px;
							}
						}
					}
					div.default_font{
						color: $white;
						
					}
					div.slider_content_width{
						margin: 0 15%;
					}
					div.desc{
						>div.default_font{
							&:nth-child(2){
								padding-bottom: 44px;
								font-style: italic;
								h4{
									line-height: 35px;
								}
							}
							&:last-child{
								text-transform: uppercase;
								h4{
									font-weight: 700;
									padding: 44px  40px 0 40px;
									border-top: 7px solid $white;
									display: inline-block;
								}
							}
						}
					}
				}
			}
		}
	}
	div.slider_cover{
		@include fill;
		background-color: rgba(230,162,101,0.7);
	}
}

section.home_galery{
	padding-bottom: 3%;
	div.special_header{
		padding: 57px 0;
		div.default_font{
			h3{
				color: $light_blue;
			}
		}
	}
	div.collage{
		width: 100%;
		height: 0;
		padding-bottom: 60%;
		position: relative;
		> div{
			position: absolute;
			> div{
				@include fill;
				@include img_large;
				border: 2px solid $white;
			}
		}
		div.pb1{
			width: 50%;
			height: 57%;
			left: 0;
			top: 0;
			div.photo{
				background-image: url('/img/home_img1.jpg');
			}
		}
		div.pb2{
			width: 50%;
			height: 43%;
			left: 0;
			bottom: 0;
			div.photo{
				background-image: url('/img/home_img2.jpg');
			}
		}
		div.pb3{
			width: 25%;
			height: 42%;
			top: 0;
			left: 50%;
			div.photo{
				background-image: url('/img/home_img3.jpg');
			}
		}
		div.pb4{
			width: 25%;
			height: 42%;
			left: 75%;
			top: 0;
			div.photo{
				background-image: url('/img/home_img4.jpg');
			}
		}
		div.pb5{
			width: 50%;
			height: 58%;
			right: 0;
			bottom: 0;
			div.photo{
				background-image: url('/img/home_img5.jpg');
			}
		}
	}
}

section.facility{
	/*position: relative;*/
	display: flex;
	align-items: center;
	padding: 7% 0;
	@include img_large;
	background-image: url('/img/blue_bg_top.jpg');
	div.desc{
		div.special_header{
			div.default_font{
				h3{
					color: $yellow;
				}
			}
		}
		> div.default_font{
			color: $white;
		}
	}
	div.circle{
		position: absolute;
		width: 30%;
		height: 0;
		padding-bottom: 30%;
		transition: 0.4s all ease;
		z-index: 2;
		&:hover{
			transform: scale(0.7);
			-moz-transform: scale(0.7);
			-o-transform: scale(0.7);
			-webkit-transform: scale(0.7);
		}
		img{
			width: 100%; height: auto;
		}
	}
	div.circle1{
		left: 5%;
	}
	div.circle2{
		right: 5%;
	}
}

.no_paddding{
	padding: 0;
}

footer.footer{
	position: relative;
	min-height: 638px;
	display: table;
	width: 100%;
	> div{
		display: table-cell;
		vertical-align: top;
		position: relative;
		height: 100%;
	}
	div.footer_left{
		width: 55%;
		background-color: $light_green;
		padding-top: 110px;
		img{
			margin-bottom: 53px;
		}
		div.special_header{
			color: $dark_green;
			div.default_font{
				h3{
					padding-bottom: 62px;
				}
			}
		}
		div.site_map{
			nav.menu{
				ul{
					li{
						margin-bottom: 30px;
						&:last-child{
							margin-bottom: 0;
						}
						a{
							color: $dark_green;
							text-transform: uppercase;
							font-size: 18px;
						}
					}
				}
			}
		}
		div.social{
			ul{
				li{
					display: inline-block;
					vertical-align: middle;
					&:first-child{
						margin-right: 26px;
					}
				}
			}
		}
		div.default_font{
			color: $dark_green;
			p{
				padding-bottom: 40px;
				&:last-child{
					padding-bottom: 0;
				}
			}
		}
	}
	div.footer_right{
		width: 45%;
		iframe{
			@include fill;
		}
	}
}

section.top_small{
	@include img_large;
	background-image: url('/img/blue_bg_top.jpg');
	width: 100%;
	div.special_header{
		padding: 90px 0;
	}
}

section.offer{
	padding-top: 88px;
	div.offer_image{
		width: 100%;
		height: 0;
		padding-bottom: 67%;
		@include img_large;
		background-image: url('/img/home_img1.jpg');
	}
	div.special_header{
		color: $medium_blue;
	}
	div.offer_container{
		padding-bottom: 103px;
	}
}

section.offer_table{
	padding-bottom: 170px;
	div.table_header{
		background-color: $purple;
		div.special_header{
			padding: 47px 0;
			div.default_font{
				text-align: center;
				h3{
					color: $white;
					padding-bottom: 0;
				}
			}
		}
	}
	table{
		width: 100%;
		border-spacing: 10px;
   		border-collapse: separate;
		th{
			background-color: $purple;
			padding:47px 0!important;
			width: 100%;
			text-align: center;
			color: $white;
			padding-bottom: 0;
			font-size: 30px;
			font-family: $special_font;
			text-transform: uppercase;
		}
		tr{
			td{
				padding: 10px 30px;
				&:first-child{
					width: 20%;
					background-color:#f3d0a1;
					text-align: center;
					color: $orange;
					font-size: 30px;
					font-family: $special_font;
					font-weight: 400;
				}&:last-child{
					width: 80%;
					background-color: #d3bdcd;
					color: #55103b;
					font-size: 18px;
					line-height: 30px;
					font-weight: 300;
				}
			}
		}
	}
}

section.about{
	div.container-fluid{
		> div.row{
			margin-bottom: 5%;
		}
	}
	overflow: hidden;
	padding-top: 5%;
	div.special_header{
		div.default_font{
			h3{
				padding-bottom: 5%;
				color: $medium_blue;
			}
		}
	}
	div.desc{
		div.default_font{
			font-size: 24px;
			line-height: 36px;
			p{
				padding-bottom: 5%;
				&:last-child{
					padding-bottom: 0;
				}
			}
		}
	}
	div.photo_left{
		> div.col-md-6{
			position: relative;
			height: 0;
			padding-bottom: 34%;
		}
		div.about_photo{
			margin-top: 10%;
			width: 82%;
			height: 100%;
			position: absolute;
			left: -3%;
			transform: rotate(-10deg);
			-webkit-box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.75);
	-moz-box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.75);
	box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.75);
			div.photo_inside{
				position: absolute;
				height: 90%;
				width: 90%;
				top:5%;
				left: 5%;
				@include img_large;
				background-image: url('/img/about_img1.jpg');
			}
		}
	}
	div.photo_right{
			> div.col-md-6{
				position: relative;
				height: 0;
				padding-bottom: 34%;
			}
		div.desc{
			text-align: right;
		}
		div.about_photo{
			margin-top: 10%;
			width: 82%;
			height: 100%;
			position: absolute;
			right: -3%;
			transform: rotate(10deg);
			-webkit-box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.75);
	-moz-box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.75);
	box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.75);
			div.photo_inside{
				position: absolute;
				height: 90%;
				width: 90%;
				top:5%;
				left: 5%;
				@include img_large;
				background-image: url('/img/about_img2.jpg');
			}
		}
	}
}

section.zapisy{
	padding-top: 92px;
	div.col-md-12{
		> div.special_header{
			div.default_font{
				h3{
					color: $medium_blue;
					padding-bottom: 80px;
				}
			}
		}
	}
	div.costs{
		margin-bottom: 30px;
		padding: 60px 0;
		background-color: $light_purple;
		color: $white;
		border-top: 10px solid $light_orange;
		border-bottom: 10px solid $light_orange;
		div.costs_top{
			margin-bottom: 60px;
		}
		div.costs_bottom{
			div.cost_box{
				display: inline-block;
				width: 40%;
				padding: 5% 0;
				margin-right: 5%;
				background-color: $light_orange;
				font-family: $special_font;
				text-transform: uppercase;
				&:last-child{
					margin-right: 0;
				}
				p{
					font-size: 18px;
				}
				h2{
					font-size: 53px;
				}
			}
		}
	}
	div.left_side{
		padding-top: 65px;
		div.add_info{
			div.default_font{
				color: $light_purple;
			}
		}
	}
	div.right_side{
		div.desc{
			div.default_font{
				> h4{
					font-family: $special_font;
					color: $dark_blue;
					padding-bottom: 64px;
				}
			}
		}	
	}
}

section.galery{
	padding-top: 82px;
	padding-bottom: 82px;
	background-color: $light_orange;
	div.galery_list{
		ul{
			li{
				position: relative;
				display: inline-block;
				width: 31.33%;
				height: 0;
				padding-bottom: 31.33%;
				margin-right: 3%;
				margin-bottom: 3%;
				background-color: $medium_green;
				display: inline-block;
				overflow: hidden;
				&:nth-child(3n){
					margin-right: 0;
				}
				&:hover{
					> div.gal_img{
						transform: scale(1.3);
					}
				}
				div.gal_img{
					@include img_large;
					@include fill;
					background-image: url('/img/home_img1.jpg');
					transition: 0.3s all ease;
				}
			}
		}
	}
}

@media only screen and (min-width: 992px) and (max-width: 10000px) {
	div.menu_box{display: block!important;}
}

@media only screen and (min-width: 1199px) and (max-width: 1500px) {
	header.header div.menu_box nav.menu ul li a{font-size: 12px;}
}

@media only screen and (min-width: 320px) and (max-width: 1499px) {
	header.header div.menu_box nav.menu ul li{margin-right: 7%;}
	section.about div.desc div.default_font{font-size: 15px; line-height: 25px;}
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
	header.header div.menu_box nav.menu ul li{margin-right: 5%;}
	section.home_about div.desc > div.default_font{font-size: 13px; line-height: 28px;}
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
	header.header div.menu_box nav.menu ul li{margin-right: 3%;}
	header.header div.menu_box nav.menu ul li a{font-size: 11px;}
	
	div.special_header div.default_font h3{font-size: 22px;}
	section img{width: 100%; height: auto;}
	div.footer_left img{width: 100%; height: auto;}
	div.footer_left div.col-md-5{width: 50%;}
	footer.footer div.footer_left div.site_map nav.menu ul li a{font-size: 15px;}
	footer.footer div.footer_left div.default_font p{font-size: 15px; padding-bottom: 24px;}
	section.zapisy div.costs div.costs_bottom div.cost_box p{font-size: 14px!important;}
	section.home_about div.desc > div.default_font{font-size: 13px; line-height: 28px;}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
	div.default_font h3{font-size: 23px;}
	div.default_font{font-size: 13px; line-height: 25px;}
	div.default_font h4{font-size: 18px;}
	
	footer.footer div.footer_left div.site_map nav.menu ul li{margin-bottom: 10px;}
	footer.footer div.footer_left div.site_map nav.menu ul li a{font-size: 13px;}
	
	div.ib1{display: none;}
	div.ib2{display: none;}
	div.ib4{display: none;}
	div.ib5{display: none;}
	section.top > div.ib3{position: relative; left: auto; top: auto; right: auto; width:  50%; order: 2;}
	section.top > div.top_desc{order:1;position: relative; left: auto; top: auto; right: auto; width:  50%;}
	section.top{padding: 30px 0; display: flex; align-items: center;}
	
	section.slider div.vertical_height div.slider_content ul li div.desc > div.default_font:last-child h4{border-top: 1px solid $white;}
	section.slider div.vertical_height div.slider_arr div.arrow{background-size: 30% auto;}
	
	section.home_about{padding-top: 0!important;}
	section.home_about div.desc{padding: 30px 0!important;}
	
	div.rwd_button{right: 15px!important;}
	
	.zapisy_top{padding-bottom: 34%!important;}
	
	div.top_desc div.default_font h3{font-size:13px!important; line-height: 23px!important; }
	div.top_desc div.special_header h3{padding-bottom: 10px;}
}

@media only screen and (min-width: 991px) and (max-width: 1499px) {
	div.logo_box img{width: 80%; height: auto;}
}

@media only screen and (min-width: 320px) and (max-width: 991px) {
	section.slider div.vertical_height div.slider_content ul li div.special_header div.default_font h3{padding-bottom: 30px;}
	section.home_about div.desc{padding-bottom: 80px;}
	div.default_font2{padding-bottom: 30px;}
	section.offer div.offer_container{padding-bottom: 30px;}
	div.flex_box{display: flex; flex-flow: column;}
	div.flex_box > div:first-child{order: 2; align-self: stretch;}
	div.flex_box > div:last-child{order: 1;  align-self: stretch;}
	div.offser_box{text-align: center}
	div.offser_box img{margin-bottom: 20px;}
	
	section.facility {display: block;}
	section.facility > div.container{margin-bottom: 30px;}
	section.facility div.circle{position: relative; left: auto; right: auto; top: auto; width: 50%; display: inline-block; text-align: center; padding-bottom: 25%; width: 50%;}
	section.facility div.circle img{width: 50%; height: auto;}
	div.circle:hover{transform: scale(1)!important;}
	
	section.about div.no_padding{padding-left: 15px; padding-right: 15px;}
	section.about div.photo_left div.about_photo{margin:0 auto 30px auto;}
	section.about div.photo_left div.about_photo{position: relative; left: auto; top: auto; right: auto;}
	section.about div.photo_right div.about_photo{position: relative; left: auto; top: auto; right: auto;}
	
	footer.footer{text-align: center; height: auto;}
	footer.footer{display: block;}
	footer.footer > div{display: block; width: 100%!important;}
	footer.footer div.footer_right{height: 300px;}
	footer.footer div.footer_left div.default_font p{padding-bottom: 0;}
	footer.footer div.footer_left div.special_header div.default_font h3{padding: 20px 0;}
	
	section.about div.photo_left > div.col-md-6{height: auto; padding-bottom: 10%;}
	section.about div.photo_right > div.col-md-6{height: auto; padding-bottom: 10%;}
	section.about div.photo_left div.about_photo{transform: rotate(0);}
	section.about div.photo_right div.about_photo{transform: rotate(0);}
	div.about_photo{height: 0; padding-bottom: 66%; margin: 0 auto;}
	.no_paddding{padding-left: 15px!important; padding-left: 15px!important;}
	section.about div.container-fluid > div.row{margin-bottom: 30px;}
	section.about div.photo_right div.desc{text-align: left;}
	
	div.logo_box img{width: 80px; height: auto;}
	header.header div.col-md-3{position: relative;}
	div.rwd_button{width: 30px; position: absolute; top:25px; right: 0;}
	div.rwd_button > div{width: 100%; height: 3px; margin-bottom: 3px; background-color: $medium_blue;}
	div.rwd_button > div:last-child{margin-bottom: 0;}
	div.menu_box{display: none;}
	header.header div.menu_box nav.menu{padding-top: 10px;}
	header.header div.menu_box nav.menu ul{text-align: center;}
	header.header div.menu_box nav.menu ul li{width: 100%; margin: 0 0 20px 0;}
	
	section.zapisy{padding-top: 30px;}
	section.zapisy div.left_side{padding-top: 0;}
	section.zapisy div.col-md-12 > div.special_header div.default_font h3{padding-bottom: 30px;}
	section.zapisy div.costs div.costs_bottom div.cost_box{display: block;  width: 80%; margin: 0 auto;}
	section.zapisy div.costs div.costs_bottom div.cost_box:first-child{margin-bottom: 30px;}
	section.zapisy div.costs div.costs_bottom div.cost_box:last-child{margin-right: auto;}
	
	section.offer{padding-top: 30px;}
	section.about{padding-top: 30px;}
	section.about div.photo_left > div.col-md-6{padding-bottom: 3%;}
	section.about div.special_header div.default_font h3{padding-bottom: 30px;}
	section.about div.desc div.default_font p{padding-bottom: 30px;}
	
	section.home_offer div.home_offer_icon img{width: 85px!important; height: auto!important;}
	
	div.photo_left div.desc{text-align: center}
	div.photo_left div.desc img{width: 82%!important; height: auto!important;}
}

@media only screen and (min-width: 320px) and (max-width: 640px) {
	div.facebook_inside{display: none!important;}
	div.facebook_inside2{display: block!important;}
}

div.middle{
	display: inline-block;
	vertical-align: top;
}

section.slider div.vertical_height div.slider_content ul li div.special_header div.default_font h3{padding-bottom: 20px!important;}

section.slider div.vertical_height div.slider_content{padding:30px 0!important;}